import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  labourData: string[] = ['Contract','Wages',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  labourForm: FormGroup = new FormGroup({});
  submitted: boolean = false;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.labourForm= this.formBuilder.group({
      labourType: ['', Validators.required],
      labourName: ['', Validators.required],
      mobileNo: ['',Validators.required],
      alternativeNo: ['',],
     panNo:  ['',Validators.required],
      accountNo: ['', Validators.required],
      bankName: ['',Validators.required],
      ifscCode: ['', Validators.required],
      tds:['',Validators.required]
     
      
    });
 
  }

  goBack() {
    this.router.navigate(['/dashboard/labour']);
  }
 
  get f() {
    return this.labourForm.controls;
  }

  onSubmit(data: any) {
    if (this.labourForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn=moment().format()
    data.isActive = true;

    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/labour'])
    })
  }
  onCancel(){
    this.labourForm.reset()
  }
}
