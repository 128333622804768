
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Labour</h2>
    <form [formGroup]="labourForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="labourType">Labour Type<span class="requried-field">*</span></label>

          <select
                    id="labourType"
                    name="labourType"
                    class="form-control"
                    formControlName="labourType"
                    [ngClass]="{ 'is-invalid': submitted && f['labourType'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of labourData"
                      [value]="data"
                      >{{ data}}
                    </option>
                  </select>
          <div *ngIf="submitted && f['labourType'].errors" class="invalid-feedback">
            <div *ngIf="f['labourType'].errors['required']">Labour Type is required</div>
          </div>
        </div>
      
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="labourName">Labour Name<span class="requried-field">*</span></label>
            <input
              type="text"
              id="labourName"
              placeholder="Enter labourName"
              class="form-control"
              formControlName="labourName"
              [ngClass]="{ 'is-invalid': submitted && f['labourName'].errors }"
            />
            <div *ngIf="submitted && f['labourName'].errors" class="invalid-feedback">
              <div *ngIf="f['labourName'].errors['required']">Labour Name is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="mobileNo">Mobile No<span class="requried-field">*</span></label>
            <input
              type="text"
              id="mobileNo"
              placeholder="Enter mobileNo"
              class="form-control"
              formControlName="mobileNo"
              [ngClass]="{ 'is-invalid': submitted && f['mobileNo'].errors }"
            />
            <div *ngIf="submitted && f['mobileNo'].errors" class="invalid-feedback">
              <div *ngIf="f['mobileNo'].errors['required']">Mobile No is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="alternativeNo">Alternative Number<span class="requried-field"></span></label>
            <input
              type="text"
              id="alternativeNo"
              placeholder="Enter alternativeNo"
              class="form-control"
              formControlName="alternativeNo"
              
            />
            
        </div>
       
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="panNo">Pan No<span class="requried-field">*</span></label>
            <input
              type="text"
              id="panNo"
              placeholder="Enter panNo"
              class="form-control"
              formControlName="panNo"
              [ngClass]="{ 'is-invalid': submitted && f['panNo'].errors }"
            />
            <div *ngIf="submitted && f['panNo'].errors" class="invalid-feedback">
              <div *ngIf="f['panNo'].errors['required']">Pan is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="bankName">Bank Name<span class="requried-field">*</span></label>
            <input
              type="text"
              id="bankName"
              placeholder="Enter bankName"
              class="form-control"
              formControlName="bankName"
              [ngClass]="{ 'is-invalid': submitted && f['bankName'].errors }"
            />
            <div *ngIf="submitted && f['bankName'].errors" class="invalid-feedback">
              <div *ngIf="f['bankName'].errors['required']">Bank Name is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
          <label for="accountNo">Account No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="accountNo"
            placeholder="Enter accountNo"
            class="form-control"
            formControlName="accountNo"
            [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
          />
          <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
            <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="ifscCode">IFSC Code<span class="requried-field">*</span></label>
            <input
              type="text"
              id="ifscCode"
              placeholder="Enter ifscCode"
              class="form-control"
              formControlName="ifscCode"
              [ngClass]="{ 'is-invalid': submitted && f['ifscCode'].errors }"
            />
            <div *ngIf="submitted && f['ifscCode'].errors" class="invalid-feedback">
              <div *ngIf="f['ifscCode'].errors['required']">IFSC is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="tds">TDS<span class="requried-field">*</span></label>
            <input
              type="text"
              id="tds"
              placeholder="Enter tds"
              class="form-control"
              formControlName="tds"
              [ngClass]="{ 'is-invalid': submitted && f['tds'].errors }"
            />
            <div *ngIf="submitted && f['tds'].errors" class="invalid-feedback">
              <div *ngIf="f['tds'].errors['required']">TDS is required</div>
            </div>
        </div>
      </div>
  

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(labourForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  