import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  labourData: string[] = ['Contract','Wages',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  labourForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  id:any;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.labourForm= this.formBuilder.group({
      labourType: ['', Validators.required],
      labourName: ['', Validators.required],
      mobileNo: ['',Validators.required],
      alternativeNo: ['',],
     panNo:  ['',Validators.required],
      accountNo: ['', Validators.required],
      bankName: ['',Validators.required],
      ifscCode: ['', Validators.required],
      tds:['',Validators.required]
     
      
    });
 
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.labourForm.patchValue({
      labourType: data.labourType,
      labourName: data.labourName,
      mobileNo: data.mobileNo,
      alternativeNo: data.alternativeNo,
     panNo:  data.panNo,
      accountNo: data.accountNo,
      bankName: data.bankName,
      ifscCode: data.ifscCode,
      tds:data.tds
    })
  }

  goBack() {
    this.router.navigate(['/dashboard/labour']);
  }
 
  get f() {
    return this.labourForm.controls;
  }

  onSubmit(data: any) {
    if (this.labourForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format()
    //data.isActive = true;

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/labour'])
    })
  }
  onCancel(){
    this.labourForm.reset()
  }
}
